<template>
  <div class="pool-switch">
    <div class="switch-item clickable" v-for="item in switchArray" @click="clickSwitch(item)">
      <img class="icon" :src="item.value==1 ? item.selectedIcon : item.icon"/>
      <div class="name" v-bind:class="{'name-selected':item.value==1}">{{ item.name }}</div>
    </div>

    <DeviceWarningModal ref="warningModal"></DeviceWarningModal>

  </div>
</template>

<script>
import DeviceWarningModal from "@/views/device/_components/DeviceWarningModal";

export default {
  components: {DeviceWarningModal},
  props: ["switchArray", "valueMap"],
  methods: {
    clickSwitch(item) {
      let key = item.writeDataId
      let readKey = item.readDataId
      let value = item.value == 1 ? 0 : 1

      if (value == 1) {
        if (this.valueMap["41"] == 0 && this.valueMap["42"] == 0 && this.valueMap["43"] == 0 && this.valueMap["44"] == 0 && this.valueMap["45"] == 0) {
          if ( key == "89" || key == "90" || key == "91"){
            this.$refs.warningModal.show("循环水泵未启动加药泵不能启动")
            return
          }

        }

        let pumpId = ["82", "83", "84", "85", "86"][this.valueMap["134"] - 1]
        if (pumpId == key) {
          this.$refs.warningModal.show("本台水泵已设定为备用泵暂不能启动")
          return
        }

      }

      if (key == "82" || key == "83" || key == "84" || key == "85" || key == "86") {
        if (this.valueMap["97"] == 1) {
          this.$refs.warningModal.show("循环水泵已设置为定时！手动不能操作")
          return
        }
      }

      if (key == "88") {
        if (this.valueMap["99"] == 1) {
          this.$refs.warningModal.show("杀菌器已设置为定时！手动不能操作")
          return
        }
      }

      if (key == "87") {
        if (this.valueMap["98"] == 1) {
          this.$refs.warningModal.show("水下灯已设置为定时！手动不能操作")
          return
        }
      }


      this.$emit("controlItems", {
        [key]:value,
        [readKey]:value
      })
      // this.$emit("control", readKey, value)
    }
  }
}
</script>

<style lang="less" scoped>
.pool-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3vw;

  .switch-item {
    margin-left: 2vw;

    .icon {
      width: 6vw;
      height: 6vw;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 2vw;
      margin-top: 3px;
    }

    .name-selected {
      color: #00BFFF;
    }
  }
}
</style>
